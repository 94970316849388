import noteApi, { NoteType, ResponseNoteType } from "../api/noteApi";
import introspectService from './IntropsectService';

class NoteService {
    async getNotes(leadId: string | number): Promise<NoteType[] | undefined> {
        try {
            const res = await noteApi.getNotesList(leadId);
            return res;
        } catch (error: any) {
            console.log(error);
            introspectService.checkToken(error.response.status.toString())
        }
    }

    // async getNoteById(noteId: string): Promise<NoteType | undefined> {
    //     try {
    //         const res = await noteApi.getNoteById({ noteId: noteId });
    //         return res;
    //     } catch (error: any) {
    //         console.log(error);
    //         introspectService.checkToken(error.response.status.toString())
    //     }
    // }

    async createNote(note: NoteType): Promise<ResponseNoteType | string | undefined> {
        try {
            const res = await noteApi.createNote(note);
            return res;
        } catch (error: any) {
            console.log(error);
            introspectService.checkToken(error.response.status.toString())
        }
    }

    async changeNote(note: NoteType): Promise<NoteType | undefined> {
        try {
            const res = await noteApi.changeNote(note);
            return res;
        } catch (error: any) {
            console.log(error);
            introspectService.checkToken(error.response.status.toString())
        }
    }

    async deleteNote(noteId: string | number): Promise<any> {
        try {
            const res = await noteApi.deleteNote(noteId);
            return res;
        } catch (error: any) {
            console.log(error);
            introspectService.checkToken(error.response.status.toString())
        }
    }


}

const noteService = new NoteService()
export default noteService
