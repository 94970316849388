import { RouteAction, RouteActionTypes, RouteState } from '../../types/route'

const path = window.location.pathname;
const initialState: RouteState = {
    route: path
}

export const routeReducer = (state = initialState, action: RouteAction) => {
    switch (action.type) {
        case RouteActionTypes.ROUTE_CHANGE:
            return {
                ...state,
                route: action.payload
            }
        default: {
            return state
        }
    }
}