import React from 'react';

const QuestionIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6819 1.33337 7.99998 1.33337C4.31808 1.33337 1.33331 4.31814 1.33331 8.00004C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667Z" fill="#B0BFC6" />
            <path d="M8 11.3334H8.00667" stroke="#617E8C" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.06 6.00014C6.21673 5.55458 6.5261 5.17887 6.9333 4.93956C7.3405 4.70024 7.81926 4.61276 8.28478 4.69261C8.7503 4.77246 9.17254 5.01449 9.47672 5.37582C9.78089 5.73716 9.94737 6.19448 9.94666 6.6668C9.94666 8.00014 7.94666 8.6668 7.94666 8.6668" stroke="#617E8C" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default QuestionIcon;