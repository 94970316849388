export type RouteState = {
    route: string
}

export enum RouteActionTypes {
    ROUTE_CHANGE = 'ROUTE_CHANGE',
}

interface ChangeRoute {
    type: RouteActionTypes.ROUTE_CHANGE;
    payload: string;
}

export type RouteAction = ChangeRoute