export type TabsErrorsState = {
    errors: string[]
}

export enum TabErrorsActionTypes {
    SET_ERROR = 'SET_ERROR',
    CLEAR_ERRORS = 'CLEAR_ERRORS',
}

interface SetError {
    type: TabErrorsActionTypes.SET_ERROR;
    payload: string;
}

interface ClearErrors {
    type: TabErrorsActionTypes.CLEAR_ERRORS;
    payload: boolean;
}

export type TabsErrorsActions = SetError | ClearErrors